<template>
  <div class="container">

    <van-search class="self-search" v-model="keyword" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="toSearch">搜索</div>
      </template>
    </van-search>

    <!-- 导航 -->
    <ul class="home-nav">
      <li class="home-nav-item" @click="toBVue('/trendsList')">
        <i class="icon-trends"></i>
        <p>学会动态</p>
      </li>
      <li class="home-nav-item" @click="toBVue('/scienceList')">
        <i class="icon-project"></i>
        <p>科教项目</p>
      </li>
      <li class="home-nav-item" @click="toBVue('/activityList')">
        <i class="icon-activity"></i>
        <p>学会活动</p>
      </li>
    </ul>

    <!-- 重要通知 -->
    <div class="home-model">
      <div class="model-title">
        <p>重要通知</p>
      </div>
      <div>
      <div class="gray-card blue-card" @click="toBVueInfo('/organDetail', important.id, 'dynamic')">
        <p class="card-notic">重要通知</p>
        <div class="card-notic-detail">
          <div class="card-notic-title">
            <p class="ellipsis">{{important.title}}</p>
            <a href="javascript:;">详情 ></a>
          </div>
          <p class="ellipsis">{{important.content}}</p>
        </div>
      </div>
      </div>
    </div>

    <!-- 学会动态 -->
    <div class="home-model">
      <div class="model-title" @click="toBVue('/trendsList')">
        <p>学会动态</p>
        <p class="more">更多 ></p>
      </div>
      <div class="btns-tab">
        <div v-for="(item, index) in dynamic" :key="index">
          <a class="btns-tab-item" :class="{'active': newsType === item.id}" @click="switchNewType(item.id)" href="javascript:;">{{item.title}}</a>
        </div>
      </div>
      <div class="news-list">
        <div v-for="(item, index) in dynamicList" :key="index">
          <div class="news-card" @click="toBVueInfo('/organDetail', item.id, 'dynamic')" v-if="newsType === item.type">
            <div class="news-desc" v-if="newsType === item.type">
              <p class="news-title">{{item.title}}</p>
              <div class="news-span">
                <p>{{item.time}}</p>
                <p>{{item.view_num}}浏览</p>
              </div>
            </div>
            <div class="list-img-box">
              <img :src="item.cover" alt="" v-if="newsType === item.type">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 科教项目 -->
    <div class="home-model">
      <div class="model-title">
        <p>科教项目</p>
      </div>
      <div>
      <div class="project-list">
        <div v-for="(item, index) in scienceList" :key="index" class="project-item" @click="toBVueInfo('/organDetail', item.id, 'science')">
          <img :src="item.cover" alt="">
        </div>
      </div>
      </div>
    </div>

     <!-- 学会活动 -->
    <div class="home-model">
      <div class="model-title" @click="toBVue('/activityList')">
        <p>学会活动</p>
        <p class="more">更多 ></p>
      </div>
      <div class="btns-tab">
        <a class="btns-tab-item" :class="{'active': activityType === 1}" @click="switchActivityType(1)" href="javascript:;">线上</a>
        <a class="btns-tab-item" :class="{'active': activityType === 2}" @click="switchActivityType(2)" href="javascript:;">线下</a>
      </div>
      <div class="news-list">
        <div v-for="(item, index) in activityList" :key="index" class="news-card">
          <div class="news-desc" @click="toBVueInfo('/organDetail', item.id, 'activity')">
            <p class="news-title">{{item.title}}</p>
            <div class="news-span">
              <p>{{item.time}}</p>
              <p>{{item.view_num}}浏览</p>
            </div>
          </div>
          <div class="list-img-box">
              <img :src="item.cover" alt="" v-if="newsType === item.type">
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";

export default {
  name: "Index",
  components: {
  },
  data() {
    return {
      value:'',
      newsType:1,
      keyword: '',
      activityType: 1,
      activityList: [],
      onlineList: [],
      offlineList: [],
      scienceList: [],
      dynamic: [],
      dynamicList: [],
      important: {
        'title': '暂无通知',
        'content': '暂无通知内容',
        'id': 0
      }
    };
  },
  created() {
    this.organ()
  },
  methods: {
    toSearch() {
      const keyword = this.keyword
      this.$router.push({path: '/searchResult', query: {keyword: keyword, activeName: 'organ'}})
    },
    organ() {
      let that = this
      that.activityList = []
      this.$api.LEARN_HOME_GET({'keyword': that.keyWorld}).then(res => {
        if (res.length === 0) {
          return false
        }
        //通知内容
        if (res.important) {
          that.important = res.important
        }
        //学会动态
        if (res.dynamic.length > 0) {
          res.dynamic.forEach((item) => {
            that.dynamic.push({
              id: item.id,
              title: item.title
            })
            item.list.forEach((itemList) => {
              that.dynamicList.push({
                id: itemList.id,
                title: itemList.title,
                view_num: itemList.view_num,
                type: Number(itemList.type),
                cover: itemList.cover ? itemList.cover : '',
                time: itemList.create_time
              })
            })
          })
        }
        //科技项目
        if (res.science.list.length > 0) {
          res.science.list.forEach((itemOnline) => {
            that.scienceList.push({
              id: itemOnline.id,
              cover: itemOnline.cover ? itemOnline.cover : '',
            })
          })
        }
        //线上
        if (res.online.list.length > 0) {
          res.online.list.forEach((itemOnline) => {
            that.onlineList.push({
              id: itemOnline.id,
              title: itemOnline.title,
              view_num: itemOnline.view_num,
              type: Number(itemOnline.type),
              cover: itemOnline.cover ? itemOnline.cover : '',
              time: itemOnline.create_time
            })
          })
        }
        //线下
        if (res.offline.list.length > 0) {
          res.offline.list.forEach((itemOffline) => {
            that.offlineList.push({
              id: itemOffline.id,
              title: itemOffline.title,
              view_num: itemOffline.view_num,
              type: Number(itemOffline.type),
              cover: itemOffline.cover ? itemOffline.cover : '',
              time: itemOffline.create_time
            })
          })
        }
        that.activityList = that.onlineList
      })
    },
    toBVue(toPath) {
      this.$router.push({path: toPath})
    },
    toBVueInfo(toPath, toid, actionVal) {
      if (!toid){
        return false
      }
      this.$router.push({path: toPath, query: {id: toid, action: actionVal}})
    },
    switchNewType(type){
      this.newsType  = type
    },
    switchActivityType(type){
      this.activityType  = type
      if (type == 1) {
        this.activityList = this.onlineList
      } else if (type == 2) {
        this.activityList = this.offlineList
      }
    }
  },
};
</script>

<style lang="less" scoped>
.home-nav{
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-top:5px;
  .home-nav-item{
    padding:0 10px;
    text-align: center;
    flex:1;
  }
  i{
    display:block;
    width:44px;
    height:44px;
    background-repeat:no-repeat;
    background-size:44px auto;
    margin:0 auto;
  }
  .icon-trends{
    background-image:url(../../assets/images/organ/icon-trends.png);
  }
  .icon-project{
    background-image:url(../../assets/images/organ/icon-project.png);
  }
  .icon-activity{
    background-image:url(../../assets/images/organ/icon-activity.png);
  }
  p{
    font-size:12px;
    color:#000;
    padding-top:3px;
  }

}
.home-banner{
  margin-top:24px;
}
.self-swipe{
  img{
    width:100%;
    max-height:100%;
  }
}
.home-model{
  margin-top:27px;
}

.blue-card{
  width:calc(100% - 28px);
  background:#2580FE;
  color:#fff;
  display:flex;
  align-items: center;
  .card-notic{
    min-width:2em;
    max-width:2em;
    font-size:16px;
    padding-right:10px;
    border-right:1px solid #FFFFFF;
  }
  .card-notic-detail{
    margin-left:18px;
  }
  .card-notic-title{
    display:flex;
    align-items: center;
    justify-content: space-between;
    font-size:15px;
    color:#fff;
    p{
      max-width: 13em;
    }
    a{
      font-size:10px;
      color:#fff;
      margin-left:10px;
    }
  }
  .notic-desc{
    font-size:12px;
    margin-top:7px;
  }
}

</style>
